<script>
import { wpService } from "@/services/wp";
export default {
  data() {
    return {
      username: "",
      processing: false,
      submitted: false,
    };
  },

  computed: {
    validEmail() {
      if (this.username) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.username);
      }
      return false;
    },
  },

  methods: {
    alertCustom(message = null) {
      if (!message) return;
      this.$buefy.dialog.alert({
        // title: "Title Alert",
        message: message,
        confirmText: "OK!",
      });
    },
    alertCustomError(message = null) {
      this.$buefy.dialog.alert({
        // title: "Error",
        message: message ? message : "Something's not good",
        type: "is-danger",
      });
    },
    verifyEmail() {
      if (!this.validEmail) return;
      this.processing = true;
      wpService.account
        .recoveryRequest(this.username)
        .then(response => {
          if (response) {
            this.alertCustom(
              `Busque el vínculo para restablecer su contraseña en la bandeja de entrada o en la carpeta de correo no deseado de ${this.username}.`,
            );
            this.$parent.close();
          } else {
            this.alertCustomError(
              "There is no user registered with that email address.",
            );
          }
        })
        .catch(error => {
          this.alertCustomError(error);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<template>
  <div id="authForgot">
    <section>
      <h2 class="title">¿Olvidó su contraseña?</h2>
      <div class="content">
        <p>
          Ingrese su dirección de correo electrónico y le enviaremos un vínculo
          para cambiar su contraseña.
        </p>
      </div>
      <form id="verifyEmail" @submit.prevent="verifyEmail">
        <div class="field">
          <b-input
            type="email"
            size="is-medium"
            id="user_login"
            name="email"
            rounded
            expanded
            placeholder="Correo electrónico"
            v-model="username"
            :disabled="processing"
          />
          <div v-show="submitted && !username" class="invalid-feedback">
            Username is required
          </div>
        </div>
        <div class="content">
          <!-- <b-input rounded expanded size="is-medium" custom-class="is-primary" :class="{ 'is-loading': processing }" type="submit" value="Enviar vínculo para restablecer" :disabled="processing" /> -->
          <b-button
            nativeType="submit"
            type="is-primary"
            size="is-medium"
            rounded
            expanded
            :class="{ 'is-loading': processing }"
            :disabled="processing"
          >
            Enviar vínculo para restablecer
          </b-button>
        </div>
      </form>
      <hr />
      <a href="" type="is-text" @click.prevent="$parent.isAction = 'login'"
        >Volver al inicio de sesión</a
      >
    </section>
  </div>
</template>
